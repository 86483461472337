export const environment = {
    production: false,
    openReplayKey: 'Rl5bYZ9Kof0jNBIrH7O6',  //OpenReplay Project Key
    turnstileSitekey: '0x4AAAAAAAYupXEGjq0_TYho',
    localTurnstilePasskey: 'ChickensAreFunny',
    supabaseProjectId: 'pkkbmcxbahqlxawckycm',
    supabaseEndpoint: 'https://pkkbmcxbahqlxawckycm.supabase.co/rest/v1/',
    supabaseUrl: 'https://pkkbmcxbahqlxawckycm.supabase.co',
    supabaseKey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBra2JtY3hiYWhxbHhhd2NreWNtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTQyMjA3OTUsImV4cCI6MjAyOTc5Njc5NX0._XzW4f-DAX3N3f34TVWaKLT6LY9F1EE4NMhBUXYjECw', // Public Key for the Supabase API
    chatCompletionEndpoint: 'https://pkkbmcxbahqlxawckycm.supabase.co/functions/v1/chat-completion'
}